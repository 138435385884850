@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-font-family: 'Mardoto', sans-serif;
  --secondary-font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  font-family: var(--primary-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--primary-font-family);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  height: 100vh;
  @apply flex flex-col;
}

.main-pages-content {
  /* margin-top: 88px; */
  display: flex;
  justify-content: center;
}

.page-container {
  @apply w-full max-w-screen-xl m-auto px-4 xl:px-0;
}

.page-content {
  @apply py-8 md:py-14;
}

.flex-center {
  @apply flex items-center justify-center;
}

.banner-header {
  @apply text-secondary-600 text-xl md:text-3xl uppercase;
  line-height: 40px;
}

.breadcrumb-item {
  @apply text-primary-700 text-3xl uppercase border-b border-secondary-300 inline-block;
  line-height: 40px;
  border-bottom: 1px solid ;
}

.form-input {
  border-radius: 5px;
  box-shadow: inset 0 2px 4px #CDCBD6;
  @apply bg-white;
}

.form-input .Mui-focused {
  @apply outline-1 outline outline-primary-700;
}

.form-input__invalid {
  @apply border border-solid border-red-700 !important;
}

.form-textarea {
  border-radius: 5px;
  box-shadow: inset 0px 1px 4px #CDCBD6;
  height: 200px !important;
  resize: none;
  overflow: auto !important;
  @apply bg-trueGrey-700 italic p-4;
}

.button-primary {
  @apply py-2 px-4 bg-primary-700 rounded-10 !important;
}

.section__divider {
  height: 1px;
  width: 165px;
  @apply bg-secondary-300 mt-4;
}

/* sign up form styles */
.signup-form__input {
  width: 350px !important;
}

.signup-form__input-active {
  @apply !bg-primary-700 !text-white;
}

.signup-form__input-active .signup-form__select-option {
  @apply !border-b-0;
}

.signup-form__select {
  @apply !bg-white;
}

.signup-form__select > .signup-form__select-option {
  @apply !border-b-0 !truncate;
}

.signup-form__select-default {
  @apply !italic !text-grey-3;
}

.signup-form__select-option {
  @apply w-full !py-1 !border-b !border-grey-500 !text-sm !whitespace-normal;
}

.auth-input__error {
  width: 350px !important;
}

.flex-1-1-100 {
  flex: 1 1 100%;
}
