.login-header {
  font-size: 24px;
  line-height: 35px;
  @apply text-blue-800 font-bold pb-7;
}

.login-form {
  width: 350px;
  @apply py-4 px-6 flex flex-col;
}

.small-close-button {
  width: 20px;
  height: 21px;
  @apply absolute right-2 top-2 border border-primary-700;
}