.search-form-group {
  width: 100%;
  @apply relative;
}
@media (min-width: 768px) {
  .search-form-group {
    min-width: 260px !important;
  }
}

.search-input {
  border-radius: 25px;
  @apply bg-grey-90 w-full overflow-hidden !important;
}

.search-input fieldset {
  @apply hidden;
}

.search-input input::-webkit-input-placeholder { /* Edge */
  @apply text-grey-80;
}

.search-input input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  @apply text-grey-80;
}

.search-input input::placeholder {
  @apply text-grey-80;
}

.search-button {
  width: 40px !important;
  min-width: 40px !important;
  border-radius: 25px !important;
  right: 0 !important;
  @apply p-0 border-0 absolute h-full bg-secondary-300 !important;
}

.search-icon path {
  @apply fill-primary-600;
}
