.menu {
  @apply max-w-screen-page;
}

.home-nav {
  @apply text-white flex flex-col w-full items-start pt-8 text-xl z-20;
  background: linear-gradient(348.94deg, #138BB1 23.31%, #2B357D 58.04%, #001375 96.28%);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

@media (min-width: 992px) {
  .home-nav {
    @apply w-full static flex-row justify-end text-base items-center !important;
    background: transparent !important;
    height: auto;
  }
}

.menu-item {
  @apply py-2 px-4 text-white;
}

@media (min-width: 992px) and (max-width: 1300px) {
  .menu-item {
    @apply px-3;
  }
}

